import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { base16AteliersulphurpoolLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';

const CreateDepositAddress = memo(() => {
    const { t, i18n } = useTranslation();

    return (
        <div className='flex-col' style={{ gap: 30 }}>
            <div>
                <p className='fs-header inter-extrabold'>{t('docs.createDepositAddress')}</p>
                <p className='fs-content p-t-10 lh-13'>{t('docs.createDepositAddressDesc')}</p>
            </div>

            <div>
                <p className='fs-title-docs inter-extrabold' id="cda_httpRequest">{t('docs.httpRequest')}</p>
                <div className='table-box'>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.endpoint')}</p>
                        </div>
                        <div className='table-content'>
                            <p>https://demoapi.bicpay.com/api/v1/generate-address</p>
                        </div>
                    </div>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.method')}</p>
                        </div>
                        <div className='table-content'>
                            <p>POST</p>
                        </div>
                    </div>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.headers')}</p>
                        </div>
                        <div className='table-content'>
                            <p>Accept: application/json</p>
                        </div>
                    </div>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.body')}</p>
                        </div>
                        <div className='table-content code-lh'>
                            <div className='codesnip'>
                                <div className='p-tb-10 p-l-15 fs-12' style={{ backgroundColor: '#f5f7ff', color: '#000000', fontWeight: 'bold', userSelect: 'none' }}>
                                    JSON
                                </div>
                                <SyntaxHighlighter
                                    language='json'
                                    style={base16AteliersulphurpoolLight}
                                    customStyle={{ margin: 0, paddingTop: 5, fontSize: 14 }}
                                    wrapLines={true}
                                    wrapLongLines={true}
                                >
                                    {`{\n\t"apikey": "string",\n\t"nonce": "integer",\n\t"sign": "string",\n\t"data": {\n\t\t"network": "string",\n\t\t"returnUrl": "string",\n\t\t"remarkID": "string"\n\t}\n}`}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                    </div>

                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.desc')}</p>
                        </div>
                        <div className='table-content'>
                            <div className='flex-str'>
                                <p className='table-subtitle'>apikey</p>
                                <p className='table-subcontent'>{t('docs.apiKey')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>nonce</p>
                                <p className='table-subcontent'>{t('docs.randomNumber')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>sign</p>
                                <p className='table-subcontent'>{t('docs.signature')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>network</p>
                                <p className='table-subcontent'>{t('docs.blockchainNetwork')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>returnUrl</p>
                                <p className='table-subcontent'>{t('docs.callbackUrl')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>remarkID</p>
                                <p className='table-subcontent'>{t('docs.uniqueIdentity')}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div>
                <p className='fs-title-docs inter-extrabold' id="cda_httpResponse">{t('docs.httpResponse')}</p>
                <div className='table-box'>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.body')}</p>
                        </div>
                        <div className='table-content code-lh'>
                            <div className='codesnip'>
                                <div className='p-tb-10 p-l-15 fs-12' style={{ backgroundColor: '#f5f7ff', color: '#000000', fontWeight: 'bold', userSelect: 'none' }}>
                                    JSON
                                </div>
                                <SyntaxHighlighter
                                    language='json'
                                    style={base16AteliersulphurpoolLight}
                                    customStyle={{ margin: 0, paddingTop: 5, fontSize: 14 }}
                                    wrapLines={true}
                                    wrapLongLines={true}
                                >
                                    {`{\n\t"status": "bool",\n\t"data": {\n\t\t"address": "string",\n\t\t"date": "dateTime",\n\t\t"callbackUrl": "string"\n\t}\n}`}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                    </div>

                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.desc')}</p>
                        </div>
                        <div className='table-content'>
                            <div className='flex-str'>
                                <p className='table-subtitle'>status</p>
                                <p className='table-subcontent'>{t('docs.trueFalse')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>address</p>
                                <p className='table-subcontent'>{t('docs.cryptoAddressGenerated')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>date</p>
                                <p className='table-subcontent'>{t('docs.cryptoAddressTime')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>callbackUrl</p>
                                <p className='table-subcontent'>{t('docs.returnUrl')}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
});

// const useStyles = makeStyles((theme) => ({

// }));

export default CreateDepositAddress;
import React, { memo, useState } from 'react';//useRef, useEffect
// import PropTypes from 'prop-types';
// import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { changeLanguage } from '@actions';
// import { makeStyles, styled } from '@mui/styles'; // useTheme
// import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink } from 'react-router-dom';

import { Box, AppBar, Toolbar, IconButton, Drawer, Link, Hidden} from '@mui/material'; //Accordion, AccordionSummary, AccordionDetails,

import MenuIcon from '@mui/icons-material/Menu';
import { FiChevronRight } from "react-icons/fi";
import { SiReaddotcv } from "react-icons/si";


import Authentication from '../Docs/Authentication';
import CreateDepositAddress from '../Docs/CreateDepositAddress';
import DepositReturnNotification from '../Docs/DepositReturnNotification';
import WithdrawalRequest from '../Docs/WithdrawalRequest';
import WithdrawalReturnNotification from '../Docs/WithdrawalReturnNotification';
import BICpayErrorResponse from '../Docs/BICpayErrorResponse';




const Docs = memo(() => {
    // const theme = useTheme();
    // const styles = useStyles();
    const { t, i18n } = useTranslation();
    // const isMountedRef = useRef(null);
    // let navigate = useNavigate();
    // let location = useLocation();
    const dispatch = useDispatch();
    // const { addAlert, setLoading } = useNotificationLoading();

    // LANGUAGE
    // const [langAnchorEl, setLangAnchorEl] = useState('null');
    // const handleLangClick = (event) => {
    //     setLangAnchorEl(event.currentTarget);
    // };

    // const [language, setLanguage] = useState('en');
    // const toggleLanguage = () => {
    //     setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'cn' : 'en'));
    // }

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    const [activeTab, setActiveTab] = useState(1);
    const docsItem = [
        // {
        //     title: 'Introduction',
        //     details: [
        //         { text: 'Introduce', link: '/docs' },
        //         { text: 'Start Now', link: '/networks' },
        //     ]
        //     // target: '_top',
        // },
        {
            id: 1,
            title: t('docs.authentication'),
            details: [
                { text: t('docs.merchantUniqueKey'), id: 'merchantUniqueKey' },
                { text: t('docs.networks'), id: 'networks' },
                { text: t('docs.generateSignature'), id: 'generateSignature' },
                { text: t('docs.generateCallbackSignature'), id: 'generateCallbackSignature' }
            ]
            // target: '_top',
        },
        {
            id: 2,
            title: t('docs.createDepositAddress'),
            details: [
                { text: t('docs.httpRequest'), id: 'cda_httpRequest' },
                { text: t('docs.httpResponse'), id: 'cda_httpResponse' }
            ]
            // target: '_top',
        },
        {
            id: 3,
            title: t('docs.depositReturnNotification'),
            details: [
                { text: t('docs.httpRequest'), id: 'drn_httpRequest'  },
                { text: t('docs.httpResponse'), id: 'drn_httpResponse'  }
            ]
            // target: '_top',
        },
        {
            id: 4,
            title: t('docs.withdrawalRequest'),
            details: [
                { text: t('docs.httpRequest'), id: 'wr_httpRequest'  },
                { text: t('docs.httpResponse'), id: 'wr_httpResponse' }
            ]
            // target: '_top',
        },
        {
            id: 5,
            title: t('docs.withdrawalReturnNotification'),
            details: [
                { text: t('docs.httpRequest'), id: 'wrn_httpRequest'},
                { text: t('docs.httpResponse'), id: 'wrn_httpResponse'}
            ]
            // target: '_top',
        },
        {
            id: 6,
            title: t('docs.bicpayErrorResponse'),
            details: [
                { text: t('docs.httpResponse'), id: 'bper_httpResponse' }
            ]
            // target: '_top',
        },

    ];

    const [docsDrawer, setDocsDrawer] = React.useState(false);

    const docsDrawerList = (newOpen) => () => {
        setDocsDrawer(newOpen);
      };


      

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={docsDrawerList(false)}>
            <div className='flex-col p-all-15 docs-list' style={{ gap: 24 }}>                
               
                {/* <Accordion style={{ padding: 0, boxShadow: 'none', margin: 0 }}>
                    <AccordionSummary
                        style={{ padding: 0, flexDirection: 'row-reverse', minHeight: 'unset' }}
                        sx={{
                            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                transform: 'rotate(90deg)'
                            },
                            '& .MuiAccordionSummary-content.Mui-expanded': {
                                margin: 0
                            },
                            '& .MuiAccordionSummary-content': {
                                margin: 0
                            },
                        }}
                        expandIcon={<FiChevronRight />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <p className='inter-bold p-l-10 fs-14'>{t('docs.authentication')}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='fs-14 flex-col p-t-10 p-lr-10' style={{ gap: 10, borderLeft: '1px solid #0002' }}>
                            <div onClick={() => setActiveTab(1)} >
                                <p className='p-tb-8 clblack'>{t('docs.merchantUniqueKey')}</p>
                                <p className='p-tb-8 clblack'>{t('docs.networks')}</p>
                                <p className='p-tb-8 clblack'>{t('docs.generateSignature')}</p>
                                <p className='p-tb-8 clblack'>{t('docs.generateCallbackSignature')}</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion> */}

                 {/* {docsItem.map((item) => (
                    <>
                        <Accordion style={{ padding: 0, boxShadow: 'none', margin: 0 }}>
                            <AccordionSummary
                                style={{ padding: 0, flexDirection: 'row-reverse', minHeight: 'unset' }}
                                sx={{
                                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                        transform: 'rotate(90deg)'
                                    },
                                    '& .MuiAccordionSummary-content.Mui-expanded': {
                                        margin: 0
                                    },
                                    '& .MuiAccordionSummary-content': {
                                        margin: 0
                                    },
                                }}
                                expandIcon={<FiChevronRight />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className='inter-bold p-l-10 fs-14'>{item.title}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='fs-14 flex-col p-t-10 p-lr-10' style={{ gap: 10, borderLeft: '1px solid #0002' }}>
                                    {item.details.map((detail, idx) => (
                                        <div key={idx} onClick={() => setActiveTab(detail.id)} >
                                            <p className='p-tb-5 clblack'>{detail.text}</p>
                                        </div>
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </>
                ))} */}
               
                {docsItem.map((item) => (
                    <div className={`flex-m ${activeTab === item.id ? 'cl-theme': 'clblack'}` } key={item.id} onClick={() => setActiveTab(item.id)}>
                        {activeTab === item.id ? <SiReaddotcv /> : <FiChevronRight />}
                        <p className='inter-bold p-l-10 fs-14'>{item.title}</p>
                    </div>
                ))}

               
            </div>
       
        </Box>
    );


    return (
        <div>
            <div>
                <AppBar className='pos-relative' style={{ zIndex: 2, borderBottom: '1px solid #eaeaea', position: 'relative' }}>
                    <Toolbar>
                        <div className='w-full flex-sb-m'>
                            <Link to="/" component={RouterLink} underline='none'>
                                <div className='max-w-full'>
                                    <img src='/logo.png' alt="logo" style={{ height: 25 }} />
                                </div>
                            </Link>
                            <div className='flex-m'>
                                <div className="bg-theme bor5 clwhite txt-center p-tb-12 p-lr-15 translateX pointer" style={{ width:100 }} onClick={() => changeSystemLanguage(i18n.resolvedLanguage == 'en' ? 'cn' : 'en')}>
                                    {i18n.resolvedLanguage == 'en' ?
                                        <p>中文</p>
                                        :
                                        <p>English</p>
                                    }
                                </div>

                            <Hidden mdUp>
                                <div className='m-l-25'>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    aria-label="menu"
                                    onClick={docsDrawerList(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                                </div>
                            </Hidden>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                
                <div className='w-full flex-sb' style={{ alignItems: 'stretch' }}>
                    <Hidden mdDown>
                        <div className='max-w-full' style={{ width: 250, minWidth: 250, height: 'calc(100vh - 64px)', overflowY: 'auto', boxShadow: '5px 0px 11px 0 #dfe8fa7d' }}>
                            {DrawerList}
                        </div>
                    </Hidden>
                    <div className='w-full p-content' style={{ height: 'calc(100vh - 64px)', overflowY: 'auto' }}>     
                        {activeTab === 0 &&
                            <div>
                                <p className='fs-header inter-extrabold'>1. {t('docs.introduce')}</p>
                                <p className='fs-content p-t-15 lh-13'>{t('docs.introduceDesc')}</p>
                            </div>
                        }
                        {activeTab === 1 &&<div><Authentication/></div>}
                        {activeTab === 2 &&<div><CreateDepositAddress/></div>}
                        {activeTab === 3 &&<div><DepositReturnNotification/></div>}
                        {activeTab === 4 &&<div><WithdrawalRequest/></div>}
                        {activeTab === 5 &&<div><WithdrawalReturnNotification/></div>}
                        {activeTab === 6 &&<div><BICpayErrorResponse/></div>}
                    </div>

                    

                    <Hidden mdDown>
                        <div className='max-w-full p-all-24' style={{ width: 250, minWidth: 250, height: 'calc(100vh - 64px)', overflowY: 'auto', boxShadow: '5px 0px 11px 0 #dfe8fa7d' }}>
                            <p className='txt-upper fs-12 p-b-15' style={{ letterSpacing: 1 }}>{t('docs.contents')}</p>    
                            {docsItem.map((item) => (
                                <div key={item.id} >
                                    {activeTab === item.id &&
                                        <div style={{ padding: 0, boxShadow: 'none', margin: 0 }}>
                                            {item.details.map((detail, idx) => (
                                                <Link component={RouterLink} underline='none' target='_top' to={`#${detail.id}`} key={idx}>
                                                    <div>
                                                        <p className='p-tb-5 clblack content-hover'>{detail.text}</p>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </Hidden>
                </div>
                <Hidden mdUp>
                    <div>
                        <Drawer open={docsDrawer} onClose={docsDrawerList(false)}>
                            {DrawerList}
                        </Drawer>
                    </div>
                </Hidden>
            </div>
        </div>
    )
});

// const useStyles = makeStyles((theme) => ({
   
// }));

export default Docs;
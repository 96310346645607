import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { base16AteliersulphurpoolLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';

const BICpayErrorResponse = memo(() => {
    const { t, i18n } = useTranslation();

    return (
        <div className='flex-col' style={{ gap: 30 }}>
            <div>
                <p className='fs-header inter-extrabold' id="bper_httpResponse">{t('docs.bicpayErrorResponse')}</p>
                <p className='fs-content p-t-10 lh-13'>
                    {t('docs.bicpayErrorResponseDesc')}</p>
            </div>

            <div>
                <p className='fs-title-docs inter-extrabold'>{t('docs.httpRequest')}</p>
                <div className='table-box'>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.body')}</p>
                        </div>

                        <div className='table-content code-lh'>

                            <div className='codesnip'>
                                <div className='p-tb-10 p-l-15 fs-12' style={{ backgroundColor: '#f5f7ff', color: '#000000', fontWeight: 'bold', userSelect: 'none' }}>
                                    JSON
                                </div>
                                <SyntaxHighlighter
                                    language='json'
                                    style={base16AteliersulphurpoolLight}
                                    customStyle={{ margin: 0, paddingTop: 5, fontSize: 14 }}
                                    wrapLines={true}
                                    wrapLongLines={true}
                                >
                                    {`{\n\t"status": false,\n\t"errors": array[],\n\t"message": "string"\n}`}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                    </div>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.desc')}</p>
                        </div>
                        <div className='table-content'>
                            <div className='flex-str'>
                                <p className='table-subtitle'>status</p>
                                <p className='table-subcontent'>{t('docs.failedStatus')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>errors ({t('docs.optional')})</p>
                                <p className='table-subcontent'>{t('docs.dataValidationErrors')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>message</p>
                                <p className='table-subcontent'>{t('docs.errorMessage')}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
});

// const useStyles = makeStyles((theme) => ({

// }));

export default BICpayErrorResponse;
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { base16AteliersulphurpoolLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';

const WithdrawalRequest = memo(() => {
    const { t, i18n } = useTranslation();

    return (
        <div className='flex-col' style={{ gap: 30 }}>
            <div>
                <p className='fs-header inter-extrabold'>{t('docs.withdrawalRequest')}</p>
                <p className='fs-content p-t-10 lh-13'>{t('docs.withdrawalRequestDesc')}</p>
            </div>

            <div>
                <p className='fs-title-docs inter-extrabold' id="wr_httpRequest">{t('docs.httpRequest')}</p>
                <div className='table-box'>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.endpoint')}</p>
                        </div>
                        <div className='table-content'>
                            <p>https://demoapi.bicpay.com/api/v1/generate-address</p>
                        </div>
                    </div>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.method')}</p>
                        </div>
                        <div className='table-content'>
                            <p>POST</p>
                        </div>
                    </div>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.headers')}</p>
                        </div>
                        <div className='table-content'>
                            <p>Accept: application/json</p>
                        </div>
                    </div>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.body')}</p>
                        </div>
                        <div className='table-content code-lh'>
                            <div className='codesnip'>
                                <div className='p-tb-10 p-l-15 fs-12' style={{ backgroundColor: '#f5f7ff', color: '#000000', fontWeight: 'bold', userSelect: 'none' }}>
                                    JSON
                                </div>
                                <SyntaxHighlighter
                                    language='json'
                                    style={base16AteliersulphurpoolLight}
                                    customStyle={{ margin: 0, paddingTop: 5, fontSize: 14 }}
                                    wrapLines={true}
                                    wrapLongLines={true}
                                >
                                    {`{\n\t"apikey": "string",\n\t"nonce": "integer",\n\t"sign": "string",\n\t"data": {\n\t\t[\n\t\t\t"amount": "float",\n\t\t\t"network": "string",\n\t\t\t"orderId": "string",\n\t\t\t"returnUrl": "string",\n\t\t\t"toAddress": "string",\n\t\t\t"token": "string",\n\t\t],\n\t\t[\n\t\t\t"amount": "float",\n\t\t\t"network": "string",\n\t\t\t"orderId": "string",\n\t\t\t"returnUrl": "string",\n\t\t\t"toAddress": "string",\n\t\t\t"token": "string",\n\t\t]\n\t}\n}`}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                    </div>

                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.desc')}</p>
                        </div>
                        <div className='table-content'>
                            <div className='flex-str'>
                                <p className='table-subtitle'>apikey</p>
                                <p className='table-subcontent'>{t('docs.apiKey')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>nonce</p>
                                <p className='table-subcontent'>{t('docs.randomNumber')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>sign</p>
                                <p className='table-subcontent'>{t('docs.signature')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>amount</p>
                                <p className='table-subcontent'>{t('docs.withdrawalAmount')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>orderId</p>
                                <p className='table-subcontent'>{t('docs.uniqueWithdrawalId')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>returnUrl ({t('docs.optional')})</p>
                                <p className='table-subcontent'>{t('docs.callbackReturnUrl')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>toAddress</p>
                                <p className='table-subcontent'>{t('docs.RecipientCryptoAddress')}</p>
                            </div>
                            <div className='flex-str'>
                                <p className='table-subtitle'>token</p>
                                <p className='table-subcontent'>{t('docs.blackchainToken')}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div>
                <p className='fs-title-docs inter-extrabold' id="wr_httpResponse">{t('docs.httpResponse')}</p>
                <div className='table-box'>
                    <div className='flex-str'>
                        <div className='table-title'>
                            <p>{t('docs.body')}</p>
                        </div>
                        <div className='table-content code-lh'>
                            <div className='codesnip'>
                                <div className='p-tb-10 p-l-15 fs-12' style={{ backgroundColor: '#f5f7ff', color: '#000000', fontWeight: 'bold', userSelect: 'none' }}>
                                    JSON
                                </div>
                                <SyntaxHighlighter
                                    language='json'
                                    style={base16AteliersulphurpoolLight}
                                    customStyle={{ margin: 0, paddingTop: 5, fontSize: 14 }}
                                    wrapLines={true}
                                    wrapLongLines={true}
                                >
                                    {`{\n\t"status": true\n}`}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

// const useStyles = makeStyles((theme) => ({

// }));

export default WithdrawalRequest;
// AUTH

// GUEST
import Home from '@pages/Home';
import Docs from '@pages/Docs';

// USER

// ONLY CAN ACCESS AFTER LOGIN - USER
export const private_routes = [
    // SETTING  
    /* {
        path: "/setting/profile",
        component: <Profile />,
        title: 'title.profile',
        showLogo: true,
        showBackBtn : true,
        backTitle: 'title.profile',
    }, */
];

// CAN ACCESS BEFORE OR AFTER LOGIN - GUEST
export const public_routes = [
    {
        path: "/",
        component: <Home />,
        showTutorBtn: false,
        showBackBtn : false,
        showLogo: true,
        // backTitle: 'title.home'
    },
    {
        path: "/docs",
        component: <Docs />,
        showTutorBtn: false,
        showBackBtn : false,
        showLogo: true,
        noHeader: true,
        // backTitle: 'title.home'
    },
];

// ONLY CAN ACCESS BEFORE LOGIN - AUTH
export const public_restricted_routes = [
    /* {
        path: "/register",
        component: <Register />,
        title: 'title.register',
        showBackBtn: true,
        noHeader: true,
    }, */
];

// FOR IMPORTED MEMBERS, FORCE CHANGE EMAIL
export const imported_user_restricted_routes = [
    /* {
        path: "/setting/profile",
        component: <Profile />,
        title: 'title.profile',
        
    }, */
];
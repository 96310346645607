import React, { memo } from 'react';//useEffect, useRef
// import PropTypes from 'prop-types';
// import _ from 'lodash';
// import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { makeStyles, styled } from '@mui/styles'; // useTheme
// import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { coldarkDark, nightOwl } from 'react-syntax-highlighter/dist/cjs/styles/prism';

const Authentication = memo(() => {
    // const theme = useTheme();
    // const styles = useStyles();
    const { t, i18n } = useTranslation();
    // const isMountedRef = useRef(null);
    // let navigate = useNavigate();
    // let location = useLocation();
    // const dispatch = useDispatch();
    // const { addAlert, setLoading } = useNotificationLoading();

    return (
        <div className='flex-col' style={{ gap: 30 }}>
            <div id="authentication">
                <p className='fs-header inter-extrabold'>{t('docs.authentication')}</p>
            </div>

            <div id="merchantUniqueKey">
                <p className='fs-title-docs inter-extrabold'>1. {t('docs.merchantUniqueKey')}</p>
                <p className='fs-content lh-13'>{t('docs.merchantUniqueKeyDesc')}</p>
            </div>

            <div id="networks">
                <p className='fs-title-docs inter-extrabold'>2. {t('docs.networks')}</p>
                <p className='fs-content lh-13'>{t('docs.networksDesc')} (<span>bsc</span>), Tron (<span>trx</span>)</p>
            </div>

            <div id="generateSignature">
                <p className='fs-title-docs inter-extrabold'>3. {t('docs.generateSignature')}</p>
                <p className='fs-content lh-13'>{t('docs.generateSignatureDesc')}{/*  {t('docs.generateSignatureDesc2')} */}</p>

                <p className='fs-content p-t-25'>{t('docs.generateSignatureList')}</p>
                <ul className='fs-content' style={{ fontWeight: 500 }}>
                    <li><p>{t('docs.generateSignatureList1')}</p></li>
                    <li><p>{t('docs.generateSignatureList2')}</p></li>
                    <li><p>{t('docs.generateSignatureList3')}</p></li>
                    <li>
                        <p className='txt-upper cl-theme'><b>{t('docs.important')}</b></p>
                        <p>{t('docs.generateSignatureList4')}</p>
                    </li>
                    <li>
                        <p className='txt-upper cl-theme'><b>{t('docs.important')}</b></p>
                        <p>{t('docs.generateSignatureList5')}</p>
                    </li>
                    <li>{t('docs.example')}: - <br />
                        <div className='codesnip'>
                            <div className='p-tb-10 p-l-15 fs-12' style={{ backgroundColor: '#1e1e1e', color: '#ffffff', fontWeight: 'bold', userSelect: 'none' }}>
                                PHP
                            </div>
                            <SyntaxHighlighter
                                language='php'
                                style={vscDarkPlus}
                                customStyle={{ margin: 0, paddingTop: 0, fontSize: 14 }}
                                wrapLines={true}
                                wrapLongLines={true}
                            >
                                {`$sign = hash("sha256”, MD5($apiKey.MD5($merchantId. $secretKey).$nonce).json_encode($data))`}
                            </SyntaxHighlighter>
                        </div>
                    </li>
                </ul>
            </div>

            <div>
                <p className='fs-title-docs inter-extrabold' id="generateCallbackSignature">4. {t('docs.generateCallbackSignature')}</p>
                <p className='fs-content lh-13'>{t('docs.generateCallbackSignatureDesc')}</p>

                <ul className='fs-content' style={{ fontWeight: 500 }}>
                    <li><p>{t('docs.generateCallbackSignatureList1')}</p></li>
                    <li><p>{t('docs.generateCallbackSignatureList2')}</p></li>
                    <li>
                        <p className='txt-upper cl-theme'><b>{t('docs.important')}</b></p>
                        <p>{t('docs.generateCallbackSignatureList3')}</p>
                    </li>
                    {/* <li>Generate the signature by hashing the <span>API key, Merchant ID, Secret Key, nonce</span> and <span>txid</span> using the MD5 and sha256 algorithm.</li> */}
                    <li>{t('docs.example')}: - <br />
                        <div className='codesnip'>
                            <div className='p-tb-10 p-l-15 fs-12' style={{ backgroundColor: '#1e1e1e', color: '#ffffff', fontWeight: 'bold', userSelect: 'none' }}>
                                PHP
                            </div>
                            <SyntaxHighlighter
                                language='php'
                                style={vscDarkPlus}
                                customStyle={{ margin: 0, paddingTop: 5, fontSize: 14 }}
                                wrapLines={true}
                                wrapLongLines={true}
                            >
                                {`$sign = hash("sha256", MD5(MD5($mer_id.$sec_key).$nonce).$data['txid'])`}
                            </SyntaxHighlighter>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
});

// const useStyles = makeStyles((theme) => ({

// }));

export default Authentication;